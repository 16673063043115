import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  setLocalStorage,
  getFromLocalStorage,
  clearLocalStorage,
  setSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from "../utilities/helpers";
import toast from "react-hot-toast";

const AuthContext = createContext({
  user: null,
  jwt: null,
});

export const AuthContextProvider = ({ children }) => {
  const [jwt, setJwt] = useState(null);
  const [user, setUser] = useState(null);

  const handleLogout = useCallback(() => {
    //* Remove cookies
    clearLocalStorage();
    clearSessionStorage();
    setUser(null);
    setJwt(null);
    toast.success("Επιτυχής αποσύνδεση");
  }, []);

  const handleLogin = useCallback((response, rememberMe = false) => {
    if (!response) return null;
    setUser(response.data.user);
    setJwt(response.data.jwt);

    if (rememberMe) {
      setLocalStorage("user", response.data.user, true);
      setLocalStorage("jwt", response.data.jwt, false);
    } else {
      setSessionStorage("user", response.data.user, true);
      setSessionStorage("jwt", response.data.jwt, false);
    }
  }, []);

  const checkLoginStatus = useCallback(async () => {
    setUser(
      getFromSessionStorage("user", true) ||
        getFromLocalStorage("user", true) ||
        null
    );
    setJwt(
      getFromSessionStorage("jwt", false) ||
        getFromLocalStorage("jwt", false) ||
        null
    );
  }, []);

  useEffect(() => {
    checkLoginStatus();
  }, [checkLoginStatus]);

  const value = useMemo(
    () => ({
      user,
      jwt,
      checkLoginStatus,
      handleLogout,
      handleLogin,
    }),
    [user, jwt, checkLoginStatus, handleLogout, handleLogin]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within AuthContextProvider");
  }

  return context;
};

export default AuthContext;
