import "./src/styles/global.scss";
import React from "react";
import { AuthContextProvider } from "./src/context/AuthContext";
import { NavContextProvider } from "./src/context/NavContext";
import reactDom from "react-dom/client";
// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
  return (
    <AuthContextProvider {...props}>
      <NavContextProvider {...props}>{element}</NavContextProvider>
    </AuthContextProvider>
  );
};

export const replaceHydrateFunction = () => {
  return (element, container) => {
    const root = reactDom.createRoot(container);
    root.render(element);
  };
};