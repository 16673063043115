import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  setLocalStorage,
  getFromLocalStorage,
  clearLocalStorage,
  setSessionStorage,
  clearSessionStorage,
  getFromSessionStorage,
} from "../utilities/helpers";
import toast from "react-hot-toast";
import { navigate } from "gatsby";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";


const NavContext = createContext({
  currentLanguage: null,
  currentLocation: null,
});

export const NavContextProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState("el-GR");
  const [currentLocation, setCurrentLocation] = useState(null);
  const [searchParams, setSearchParams] = useState(null);

  const getParams = useCallback(() => {
    let tempParams = {};
    if (currentLocation) {
      if (currentLocation?.search !== "") {
        let search = currentLocation?.search.replace("?", "").split("&");
        search.map((param) => {
          const [key, value] = param.split("=");
          tempParams = {
            ...tempParams,
            [key]: value,
          };
          return true;
        });
      }
      setSearchParams(tempParams);
    }
  }, [currentLocation]);

  const updateSearchParams = useCallback((params) => {
    if (params) {
      const tempParams = [];
      Object.entries(params).forEach((item) => {
        if (item[1] && item[1] !== "") {
          tempParams.push(item.join("="));
        }
      });
      navigate(`?${tempParams.join("&")}`);
    }
  }, []);

  useEffect(() => {
    getParams();
  }, [currentLocation, getParams]);

  const value = useMemo(
    () => ({
      currentLanguage,
      setCurrentLanguage,
      setCurrentLocation,
      currentLocation,
      searchParams,
      setSearchParams,
      updateSearchParams,
    }),
    [
      currentLanguage,
      setCurrentLanguage,
      setCurrentLocation,
      currentLocation,
      searchParams,
      setSearchParams,
      updateSearchParams,
    ]
  );

  return <NavContext.Provider value={value}>{children}</NavContext.Provider>;
};

export const useNav = () => {
  const context = useContext(NavContext);

  if (context === undefined) {
    throw new Error("useNav must be used within NavContextProvider");
  }

  return context;
};

export default NavContext;
