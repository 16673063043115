export const setLocalStorage = (name, data, isJSON = false) => {
  if (isJSON) {
    return localStorage.setItem(name, JSON.stringify(data));
  }
  return localStorage.setItem(name, data);
};

export const getFromLocalStorage = (name, isJSON = false) => {
  if (isJSON) {
    return JSON.parse(localStorage.getItem(name));
  }
  return localStorage.getItem(name);
};

export const clearLocalStorage = () => {
  localStorage.clear();
  return true;
};
export const removeFromLocalStorage = (name) => {
  localStorage.removeItem(name);
  return true;
};
export const setSessionStorage = (name, data, isJSON = false) => {
  if (isJSON) {
    return sessionStorage.setItem(name, JSON.stringify(data));
  }
  return sessionStorage.setItem(name, data);
};

export const getFromSessionStorage = (name, isJSON = false) => {
  if (isJSON) {
    return JSON.parse(sessionStorage.getItem(name));
  }
  return sessionStorage.getItem(name);
};

export const clearSessionStorage = () => {
  sessionStorage.clear();
  return true;
};
export const removeFromSessionStorage = (name) => {
  sessionStorage.removeItem(name);
  return true;
};
